"use client";

import { login } from "@/features/authentication/lib/action";
import { clearHtmlTags } from "@/utils";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import toast from "react-hot-toast";
import { UserCredentials } from "./lib/type";
const defaultValues: UserCredentials = {
  password: "",
  username: ""
};
export function LoginForm() {
  const {
    t
  } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleOnSubmit = async (values: UserCredentials) => {
    setIsLoading(true);
    await login(values).then(response => {
      if (!response.success) {
        toast.error(clearHtmlTags(response.error));
      }
      if (response.success) {
        toast.success(t("login.form.success"));
        window.location.replace("/");
      }
    }).finally(() => setIsLoading(false));
  };
  return <Form className="login-form" initialValues={defaultValues} name="normal_login" onFinish={handleOnSubmit} scrollToFirstError size="large" data-sentry-element="Form" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <Form.Item label={t("login.form.username")} labelAlign="right" name="username" rules={[{
      required: true
    }]} data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
        <Input autoComplete="username" placeholder={t("login.form.username")} prefix={<UserOutlined className="site-form-item-icon" />} data-sentry-element="Input" data-sentry-source-file="LoginForm.tsx" />
      </Form.Item>
      <Form.Item className="mb-0" label={t("login.form.password")} name="password" rules={[{
      required: true
    }]} data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
        <Input.Password autoComplete="current-password" placeholder={t("login.form.password")} prefix={<LockOutlined className="site-form-item-icon" />} type="password" data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx" />
      </Form.Item>
      <Form.Item className="mb-2" data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
        <Link className="text-blue-500" href={"/forgot-password"} data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">
          {t("login.form.forgotPassword")}
        </Link>
      </Form.Item>
      <Form.Item data-sentry-element="unknown" data-sentry-source-file="LoginForm.tsx">
        <Button className="submit-btn bg-primary hover:bg-primary/70" htmlType="submit" loading={isLoading} type="primary" data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
          {t("login.form.submitBtn")}
        </Button>
      </Form.Item>
      <div className="w-full text-center">
        <p>{t("login.form.noAccount")} </p>
        <Link className="text-blue-500" href={"/register"} data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">
          {t("login.form.register")}
        </Link>
      </div>
    </Form>;
}
export default LoginForm;